@import 'styles/global-settings.scss';
@import '../../../styles/colorVariable';
.workWeek {
    background-color: #1957f305;
    border-left: 1px solid #f1f2f380;
    padding: 10px 20px;

    &__row {
        margin-top: 10px;
    }

    &__button {
        height: 26px;
        line-height: 16px;
        color: $neutral-5;
    }
    &__title {
        color: $title-45;
    }
    &__buttons {
        border-color: $neutral-5;
        color: $neutral-5;
        background-color: $neutral-1;
        height: 24px;
        width: 24px;
        padding: 0px 4px !important;
    }
}
.config_box,
.select-plant {
    background-color: #ffffff;
    box-sizing: border-box;
    margin-top: 12px;
}

.cardDefaultClass {
    background-color: #ffffff;
    width: 86%;
    height: 130px;
    margin: auto;
    border-radius: 16px;
    padding: 15% 10%;
    cursor: pointer;
}

@mixin all-active-card {
    background-color: #ffefe5;
    width: 86%;
    height: 130px;
    margin: auto;
    border-radius: 16px;
    border: 1px solid #ff5c00d9;
    padding: 15% 10%;
    cursor: pointer;
}

.DayConfiguration,
.ShiftConfiguration,
.Holidays,
.BreakTime {
    @include all-active-card;
}

.emptyTableComponent {
    position: absolute;
    top: 34%;
    left: 34%;
}

.configAllBox {
    &__tableBox {
        margin-left: 0px !important;
        height: calc(100vh - 240px);
    }
    &__cards {
        padding: 16px 0 12px 0;
    }
    &__col {
        background-color: #f1f2f380;
        padding-bottom: 20px;
        overflow-y: scroll;
        height: 100%;
    }
    &__col2 {
        margin-top: 22px;
    }
    &__tableArea {
        height: 100%;
        overflow-y: scroll;
    }
    &__noData {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

.mainCard {
    justify-content: center;
    text-align: center;
}

.TableBox {
    padding: 10px;
}

.DateComponentMain {
    padding: 10px !important;
    box-sizing: border-box;
}

.addNewButton {
    align-items: flex-end;
}

.selecteDays {
    margin-top: 10px;
    width: 158px;
}
.headerRightSection {
    display: flex;
    flex-direction: row;
    padding: 0px 20px;
    gap: 16px;
    &__options {
        display: flex;
        flex-direction: column;
    }
    &__heading {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        padding-bottom: 8px;
    }
}
.ant-select-single.headerRightSection__select {
    width: 200px;

    .ant-select-selector {
        height: 24px;
        border-radius: 2px;
    }
    span.ant-select-selection-item {
        line-height: 22px;
    }
    &::placeholder {
        line-height: 22px;
    }
}

.selectedDayButtons {
    background-color: $date-range;
    color: $primary-6;
    border-color: $primary-6;
    height: 24px;
    width: 24px;
    padding: 0px 4px !important;
}
.selectedDailyButton {
    background-color: $date-range;
    color: $primary-6;
    border-color: $primary-6;
    height: 26px;
    line-height: 16px;
}
.workWeekRow {
    margin-left: 16px;
}
.weekDaysBox {
    margin-left: 16px;
}
