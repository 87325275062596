@import '../../../../styles/global-settings.scss';

.attributeFormWrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    &__content__wrapper {
        width: 100%;
    }

    &__form {
        width: 80%;
        .ant-form-item-control-input {
            width: 80% !important;
        }
        .ant-select.ant-select-in-form-item {
            width: 100% !important;
        }
        .DescriptionBox {
            .ant-form-item-control-input {
                width: 90% !important;
            }
        }
    }

    &__imageUpload {
        @include flexprob;
        flex-direction: column;
        color: $disabled-text;
        gap: 8px;
        margin: 20px 0px;
    }
    &__wrapper {
        margin: auto;
        padding: 20px 0;
    }
}
