@import '../../../styles/global-settings.scss';

.customHeader {
    height: 116px;
    background-color: $neutral-1;
    @include flexprob;

    &__child {
        margin: 12px 24px 16px 24px;
        width: 100%;
        height: 88px;
        display: flex;
        justify-content: space-between;
        &__titledatePicker {
            margin-top: 19px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 70%;
        }
        &__clock {
            margin-top: 19px;
            width: 30%;
        }
    }
    &__child-clock {
        &__applyButton {
            width: 100px;
        }
    }
    &__child-datePicker {
        display: flex;
        gap: 12px;
        justify-content: flex-start;
        .ant-select {
            width: 140px;
            .ant-select-selector {
                background-color: $date-range;
                border: 1px solid $date-rangeBorder;
                .ant-select-selection-item {
                    .rangePicker-label {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 4px;
                        .anticon-calendar {
                            svg {
                                color: $primary-6;
                            }
                        }
                        &__text {
                            color: $primary-6;
                        }
                    }
                }
            }
        }

        .ant-picker,
        .ant-picker-range {
            .ant-picker-input {
                input {
                    color: $title-45;
                }
            }
        }
    }
    &__child-applyButton {
        width: 100px;
    }
    &__child-resetButton {
        width: 32px;
        height: 32px;
        border-radius: 5px;
        border: 1px solid $reset-border;
        background: $reset-background;
        @include flexprob;
        cursor: pointer;
    }
    &__child-assumptionButton {
        width: 32px;
        height: 32px;
        @include flexprob;
        cursor: pointer;
    }
}
.dateRange-list {
    width: 140px !important;
    .ant-select-item-option-content {
        justify-content: center;
    }
    .rangePicker-label {
        display: flex;
        justify-content: center;
        .anticon-calendar {
            display: none;
        }
    }
    .rc-virtual-list-holder {
        max-height: none !important;
    }
    .rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-show {
        display: none;
    }
}
.customHeader__child-applyButton .custom-apply:hover {
    background: transparent !important;
    border: 1px solid $primary-5;
    span {
        color: $primary-5;
    }
}
