@import '../../../../styles/global-settings.scss';

.coalIntakeVsProductionWrapper {
    width: 100%;
    height: 310px;
    margin-bottom: 16px;
    font-weight: 500;
    .ant-card-body {
        width: 100%;
        height: 100%;
        .coalIntakeVsProductionContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            &__charts {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                div:nth-child(1) {
                    width: 100%;
                    height: 100%;
                    @include flexprob;
                    overflow: visible !important;
                    .highcharts-credits {
                        display: none;
                    }
                }
            }
            &__span-heading {
                color: $title-85;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.96px;
            }
            &__span-unit {
                color: $title-45;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.72px;
            }
            .highcharts-root {
                height: 100%;
            }
            &__loader {
                @include flexprob;
                width: 100%;
                height: 100%;
            }
        }
    }
}
