@import './colorVariable.scss';

@mixin flexprob {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin textfontstyle {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  line-height: 28px;
}

@mixin fontTextStyle {
    margin: 16px;
    color: $title-85;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    line-height: 22px;
    letter-spacing: 0.96px;
}


