@import '../../../../styles/global-settings.scss';
.directEfficencyWrapper {
    height: 155px;
    width: 100%;
    font-weight: 500;

    .ant-card-body {
        width: 100%;
        height: 100%;
        .directEfficencyContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            &__heading span{
                font-size: 16px;
            }
            &__charts {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
            &__loader {
                @include flexprob;
                width: 100%;
                height: 100%;
            }
                .solidGauge{
                   height: 100% !important;
                    .highcharts-container{
                        height: 100% !important;
                        svg{
                            height: 100%;
                        }
                        .highcharts-data-labels{
                            .highcharts-label{
                               @include flexprob;
                            }
                        }
                    }
                }
            }
        }
    }
}
