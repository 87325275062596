@import '../../../../styles/global-settings.scss';

.EconomizerAndAPHWrapper {
    height: 187px;
    width: 100%;
    margin-bottom: 16px;
    font-weight: 500;
    .ant-card-body {
        width: 100%;
        height: 100%;
        .EconomizerAndAPHContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            &__charts {
                height: 73px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                div:nth-child(1) {
                    width: 100%;
                    height: 100%;
                    @include flexprob;
                    overflow: visible !important;
                    .highcharts-credits {
                        display: none;
                    }
                }
            }
            &__loader {
                @include flexprob;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__value {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    &__column {
        display: flex;
        align-items: center;
    }
    &__name {
        color: $title-85;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.96px;
    }
    &__degree {
        color: $title-45;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.72px;
    }
    &__num {
        color: $black;
        font-size: 18px;
        font-weight: 600;
    }
    &__val {
        color: $title-45;
        font-size: 12px;
        font-weight: 500;
    }
    &__row {
        width: 100%;
        height: 100%;
    }
}
