@import '../../../../styles/global-settings.scss';

.assumptionsAndFormulaeWrapperScrolContent {
    overflow-y: scroll;
    height: calc(100vh - 165px);
}

.assumptionsAndFormulaeWrapper {
    min-height: calc(100vh - 196px);
    @include fontTextStyle;

    &__cardContent {
        height: 100%;
    }
    &__cardBody {
        height: 100%;
        cursor: auto;
        .title {
            text-align: center;
            display: flex;
            align-items: center;
            .heading {
                color: rgba(0, 0, 0, 0.85);
            }
            .subHeading {
                color: rgba(0, 0, 0, 0.45);
            }
        }
        .closeIcon {
            text-align: end;
        }
        .assumptionParent {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1%;
            margin-top: 16px;
            margin-bottom: 16px;
        }
        .desc {
            padding: 20px;
            @include flexprob;
            width: 100%;
            height: 112.21px;
            top: 67.36px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            background-color: rgba(0, 0, 0, 0.03);
            // opacity: 3%;
        }
        .des {
            color: #000;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 175% */
            letter-spacing: 0.64px;
        }
        .filter-navigator {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 14px;
            padding: 15px 11px;
            height: 48px;
            // position: relative;
            cursor: pointer;
            filter-search {
                width: 209px;
            }
            .ant-input-affix-wrapper {
                gap: 10px;
                padding: 7px;
                border-radius: 8px !important;
                border: 1px solid #eee !important;
                background: #fcfcfe !important;
            }

            .reset-button {
                @include flexprob;
                // position: absolute;
                // top: 50%;
                // right: 18px;
                // transform: translateY(-50%);
                // color: #1890ff;
                cursor: pointer;
            }
            .anticon svg {
                width: 16px;
                height: 16px;
            }
            .ant-input-affix-wrapper {
                color: rgba(0, 0, 0, 0.3);
                border: none;
                width: 209px;
                height: 40px;
                background: #eeeeee;
            }
        }
        th.ant-table-cell {
            color: var(--character-title-85, rgba(0, 0, 0, 0.85));
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
        }
        td.ant-table-cell {
            color: var(--character-title-85, rgba(0, 0, 0, 0.85));
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }
    }
}
