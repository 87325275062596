@import '../../styles/global-settings.scss';

.manualEntry {
    .manualEntryWrapperScrolContent {
        overflow-y: scroll;
        height: calc(100vh - 165px);
    }
    .manualEntryWrapper {
        height: calc(100vh - 196px);
        @include fontTextStyle;

        &__cardContent {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__cardBody {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 45px;
        }
    }
}
