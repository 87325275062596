@import '../../../styles//global-settings.scss';

.forgotPassword {
  .login-btn {
    margin-top: 80px;
  }
  #login_form .copyright-text {
    margin-top: 100px;
  }
  .forgot-pass {
    margin-bottom: 0;
  }
}
