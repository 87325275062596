@import '../../styles/global-settings.scss';

.ant-image-img {
    border-radius: 50% !important;
}
.layoutWrapper {
    min-height: 100vh;
}
.ant-layout {
    aside.ant-layout-sider,
    .ant-layout-sider-collapsed {
        transition: all 0.2s;
    }
    .ant-layout-sider-children {
        background: #f1f3fc;
        display: flex;
        flex-direction: column;
        .empty-space {
            flex-grow: 1;
        }
        .switch-module {
            @include flexprob;
            justify-content: flex-end !important;
            cursor: pointer;
            color: $primary-2;
            padding: 16px 16px;
        }
    }

    .ant-layout-sider-collapsed {
        width: 47px !important;
        flex: 0 0 47px !important;
        max-width: 47px !important;
        min-width: 47px !important;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        position: static;
        z-index: 2;
    }
    .expand {
        width: 273px !important;
        flex: 0 0 273px !important;
        max-width: 273px !important;
        min-width: 273px !important;
        box-shadow: 4px 0px 6px $title-08;
        position: static;
        z-index: 2;
    }
    header.ant-layout-header.header {
        height: 48px !important;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.06);
        background-color: white;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        align-content: center;
        padding: 7px 20px;
        position: static;
        z-index: 1;
        // @include themify($themes) {
        //   background: themed('bodyBackgroundColor');
        // }
        .header-setting-container {
            display: flex;
            justify-content: flex-end;
            padding: 8px 0px;
            height: 47px;
            align-items: center;
            img {
                border-radius: 50%;
                height: 30px;
            }
            .customer-container {
                @include flexprob;
                gap: 8px;
                height: 48px;
                padding-right: 12px;
            }
            .profile-container {
                .ant-image img {
                    width: 50% !important;
                    height: 50% !important;
                    border-radius: 50% !important;
                    object-fit: contain;
                }

                .ant-image {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                padding-left: 26px;
                gap: 16px;
                .profile-setting {
                    display: flex;
                }
            }
            .ant-divider-vertical {
                height: 33px;
                border-inline-start: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
        .logout {
            color: $black-1;
        }
    }
}

.user-profile-popover-content {
    width: 200px;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.popover-content {
    display: flex;
    flex-direction: column;
    place-items: center;
}
.signOut {
    align-items: center;
}
