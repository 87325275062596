@import '../../../../styles/global-settings.scss';

.boilerUtilisationWrapper {
    width: 100%;
    height: 256px;
    font-weight: 500;
    .ant-card-body {
        width: 100%;
        height: 100%;
        .boilerUtilizationContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            &__heading {
                font-size: 16px;
            }
            &__charts {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                div:nth-child(1) {
                    width: 100%;
                    height: 100%;
                    @include flexprob;
                    overflow: visible !important;
                    .highcharts-credits {
                        display: none;
                    }
                }
            }
            .highcharts-root {
                height: 50%;
            }
            &__loader {
                @include flexprob;
                width: 100%;
                height: 100%;
            }
        }
    }
}
