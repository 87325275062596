@import '../../../../styles/colorVariable';
@import '../../../../styles/mixins';
.myPreferencesComponent {
  padding: 12px;
  .ant-card-body {
    padding: 0;
    .preferenceContainer {
      &__heading {
        padding: 24px;
        border-bottom: 1px solid $divider;
      }
      &__content {
        min-height: 802px;
        width: 100%;
        &__child {
          max-width: 936px;
          margin: 0 auto;
          margin-top: 55px;
        }
        &__items {
          min-height: 234px;

          .contentCardOne {
            padding: 24px;
            border: 1px solid $neutral-5;
            border-radius: 12px;
            .ant-card-body {
              display: flex;

              justify-content: flex-start;
            }
            &__header {
              display: flex;
              justify-content: flex-start;
              flex-direction: column;
              width: 50%;
              span:nth-child(1) {
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: $title-85;
              }
              span:nth-child(2) {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: $title-65;
              }
            }
            &__fields {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
              margin-top: 7px;
            }
          }
          .contentCardTwo {
            padding: 24px;
            border: 1px solid $neutral-5;
            border-radius: 12px;
            &__header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 32px;
              &__title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: $title-85;
              }
              div:nth-child(1) {
                width: 58px;
              }
            }
            &__heading {
              font-weight: 400;
              font-size: 18px;
              line-height: 22px;
              color: $title-85;
            }
            &__fields {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
              margin-top: 7px;
              &__details {
                height: 100%;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                width: 100%;
                gap: 24px;
                .ant-form-item {
                  width: 100%;
                  max-width: 280px;
                  .ant-row {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .ant-col:nth-child(1) {
                      label {
                        span {
                          font-family: 'Inter';
                          font-style: normal;
                          font-weight: 400;
                          font-size: 14px;
                          line-height: 22px;
                          color: $title-85;
                        }
                      }
                    }
                    .ant-col:nth-child(2) {
                      width: 100%;
                      span {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 22px;
                        display: flex;
                        align-items: center;
                        color: $title-45;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &__items:nth-child(2) {
          margin-top: 24px;
        }
      }
    }
  }
}
