@import '../../../../styles/global-settings.scss';

.createRolerTableWrapper {
  thead.ant-table-thead {
    @include textfontstyle;
    font-weight: 500;
    font-size: 14px;
    color: $title-85;
  }

  tr.ant-table-row {
    @include textfontstyle;
    font-weight: 400;
    font-size: 14px;
    color: $title-85;
  }

  .ant-avatar-group > *:not(:first-child) {
    background-color: $progress-border;
  }

  .column-title,
  &__columnTitle {
    display: flex;
    justify-content: space-between;
    &__name {
      display: grid;
      grid-template-columns: 10% 1fr 10%;
      justify-content: center;
      align-items: center;
      .sortArrows {
        @include flexprob;
        flex-direction: column;
        gap: 2px;
      }
    }

    .sortArrows {
      @include flexprob;
      flex-direction: column;
      gap: 2px;
    }
  }

  &__nameData {
    gap: 8px;
    justify-content: flex-start;
    color: $primary-4;
    align-items: center;
    span {
      @include textfontstyle;
    }
    span.ant-avatar.ant-avatar-circle.mr-10 {
      color: $neutral-1;
      margin-right: 10px;
    }
  }

  &__status {
    @include flexprob;
    justify-content: flex-start;
    gap: 8px;
  }
}
