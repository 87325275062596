$primary-1: #e8ecfd;
$primary-2: #e6f7ff;
$primary-3: #153ad1;
$primary-6: #2e53ea;
$primary-5: #5c79ef;
$primary-4: #2f54eb;
$primary-7: #153ad1;
$button: #2f54eb;

$neutral-1: #ffffff;
$neutral-2: #dadde0;
$neutral-3: #f5f5f5;
$neutral-4: #f0f0f0;
$neutral-4: #f0f0f0;
$neutral-5: #d9d9d9;
$neutral-6: #f6f7f9;
$neutral-7: #fafafa;
$white: #fcfcfc;

$black-1: #172a3f;
$gray-1: #f3f3f3;
$gray-2: #848484;
$gray-3: #f6f7f9;
$gray-4: #546271;
$gray-5: #6d7986;
$gray-6: #434343;
$gray-7: #838383;
$gray-8: #00000073;

$progress-border: #f86806;
$progress-inactive-bg: #0000001a;

$title-85: rgba(0, 0, 0, 0.85);
$title-65: rgba(0, 0, 0, 0.65);

$title-08: rgba(0, 0, 0, 0.08);
$title-5: rgba(0, 0, 0, 0.5);
$title-35: rgba(0, 0, 0, 0.35);
$title-45: rgba(0, 0, 0, 0.45);
$border: rgba(0, 0, 0, 0.1);
$box-shadow: rgba(0, 0, 0, 0.016);

$disabled-text: rgba(0, 0, 0, 0.25);
$divider: rgba(0, 0, 0, 0.03);
$shadow: rgba(0, 0, 0, 0.016);
$border: rgba(0, 0, 0, 0.1);

$geek-blue: #597ef7;
$volcano-4: #ff9c6e;
$polar-green-1: #f6ffed;
$polar-green-3: #b7eb8f;
$polar-green-6: #52c41a;

$red-1: #fff0f0;
$red-2: #ffadae;
$red-3: #ff4d4f;
$red-4: #fd3d33;

$button: #2f54eb;

$neutral-1: #ffffff;
$neutral-2: #dadde0;
$neutral-3: #f5f5f5;
$neutral-4: #f0f0f0;
$neutral-5: #d9d9d9;
$neutral-6: #f6f7f9;
$neutral-7: #fafafa;

$black-1: #172a3f;
$gray-1: #f3f3f3;
$gray-2: #848484;
$gray-3: #f6f7f9;
$gray-3: #f6f7f9;
$gray-4: #546271;
$gray-5: #6d7986;
$gray-6: #434343;

$progress-border: #f86806;
$progress-inactive-bg: #0000001a;

$progress-border: #f86806;
$progress-inactive-bg: #0000001a;

$title-85: rgba(0, 0, 0, 0.85);
$title-65: rgba(0, 0, 0, 0.65);

$title-65: rgba(0, 0, 0, 0.65);

$title-08: rgba(0, 0, 0, 0.08);
$title-45: rgba(0, 0, 0, 0.45);
$border: rgba(0, 0, 0, 0.1);
$box-shadow: rgba(0, 0, 0, 0.016);
$border: rgba(0, 0, 0, 0.1);
$box-shadow: rgba(0, 0, 0, 0.016);

$disabled-text: rgba(0, 0, 0, 0.25);
$divider: rgba(0, 0, 0, 0.03);
$shadow: rgba(0, 0, 0, 0.016);
$border: rgba(0, 0, 0, 0.15);
$black: #1e1e1e;
$black-7: #000000;

$geek-blue: #597ef7;

$polar-green-1: #f6ffed;
$polar-green-3: #b7eb8f;
$polar-green-6: #52c41a;
$green: #4ed964;

$red-1: #fff0f0;
$red-2: #ffadae;
$red-3: #ff4d4f;
$red-4: #fb554c;

$yellow-1: #faad14;
$reset-border: rgba(248, 104, 6, 0.5);
$reset-background: #fff8f3;
$clear-all: #f2644e;
$date-range: #f1f3fc;
$date-rangeBorder: rgba(46, 83, 234, 0.5);
