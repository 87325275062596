.custom-data-label {
    color: #000000;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.custom-unit-label {
    color: grey;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}
