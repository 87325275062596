@import '../../styles/fontStyles';
@import '../../styles/mixins';

.calendarConfigurator {
    width: 100%;
    background: white;
    &__headerWrapper {
        padding: 21px 24px;
    }
    &__tabsWrapper {
        @include textfontstyle;
        margin-top: 16px;
    }
    .ant-tabs-nav {
        margin: 0px 0px 0px 24px;
    }
    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $primary-6;
    }
    .ant-tabs-ink-bar {
        background-color: $primary-6;
    }
    .ant-tabs-tab:hover {
        color: $primary-6;
    }
}
