@import '../../../../styles/global-settings.scss';

.kpiTrendAreaWrapperScrolContent {
    overflow-y: scroll;
    height: calc(100vh - 165px);
}

.kpiTrendAreaWrapper {
    min-height: calc(100vh - 196px);
    @include fontTextStyle;

    &__cardContent {
        height: 100%;
        &__header-exportCancel {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;
        }
        &__header-exportButton {
            max-width: 168px;
            button {
                width: 100%;
                border: 1px solid var(--neutral-5, #d9d9d9);
                background: transparent;

                span:nth-child(1) {
                    svg {
                        fill: $title-45;
                        color: $title-45;
                        stroke: none;
                    }
                }
                span:nth-child(2) {
                    color: $title-85;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
            button:hover {
                border: 1px solid $primary-5;
                background-color: transparent !important;
                span:nth-child(1),
                .custom-download.as.csv {
                    svg {
                        color: $primary-5 !important;
                        stroke: none;
                    }
                    span {
                        color: $primary-5 !important;
                    }
                    path {
                        fill: $primary-5 !important;
                    }
                }
                span:nth-child(2) {
                    color: $primary-5 !important;
                }
            }
        }
        .custom-download.as.csv {
            svg {
                color: $title-85;
                stroke: none !important;
            }
            span {
                color: $title-85;
            }
            path {
                fill: $title-85;
            }
        }
    }
    &__cardBody {
        height: 100%;
        .title {
            text-align: center;
            display: flex;
            align-items: center;
            .heading {
                color: $title-85;
            }
            .subHeading {
                color: rgba(0, 0, 0, 0.45);
            }
        }
        .closeIcon {
            text-align: end;
        }
        .trendArea {
            padding-top: 20px;
        }
    }
}
