@import '../../../../styles/global-settings.scss';

.ant-modal-root .ant-modal-mask {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(7.5px);
}
.confirmationModal.save {
    width: 509px !important;
}
.confirmationModal.delete {
    width: 593px !important;
}
.confirmationModal {
    width: 509px !important;
    .ant-modal-content {
        padding: 0px !important;
        .ant-modal-body {
            @include flexprob;
            padding: 52px 0px 22px 0px;
            flex-direction: column;
            gap: 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            span {
                @include textfontstyle;
                color: $title-85;
                text-align: center;
                width: 355px;
            }
        }
        .ant-modal-close {
            top: 26px !important;
        }
        .ant-modal-footer {
            @include textfontstyle;
            text-align: center;
            margin-top: 0px !important;
            padding: 24px 0px;

            .ant-btn-primary {
                padding: 4px 0px;
                width: 109px;
            }
            .ant-btn-default {
                width: 109px;
            }
        }
    }
}

.ant-switch .ant-switch-inner {
    width: 44px !important;
}

.ant-switch .ant-switch-loading-icon.anticon {
    width: auto !important;
    line-height: 0px !important;
}
.confirmationModal.blaDeactivation .ant-modal-content .ant-modal-body span {
    width: 400px;
}
span.noActiveDevice {
    display: block;
    width: 400px;
    font-size: 18px;
}
.confirmationModal.calendarCancelModal .ant-modal-footer .ant-btn-primary {
    border-color: $red-3;
    color: $red-3;
    background-color: $neutral-3;
}
.switchSpan{
    padding-left: 10px;
}
